@import '~antd/dist/antd.css';
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body{
  /*background: #2a2a2a;*/
  padding: 20px 10px;
  margin-bottom: 80px !important;
  padding-bottom: 60px; 
  height: auto !important;
}
.addView{
  margin-bottom: 30px;
}
.uploadingText{
  color: #fff;
}

.alert{
  margin-bottom: 10px;
  margin-top: 10px;
}
.save{
  margin-bottom: 20px !important;
  margin-top: 10px !important;
}
.hiddenView{
  display: none;
}
.pagination{
  margin-top: 30px;
  margin-bottom: 30px;
}


body *{
  font-family: "Montserrat" !important;
}
.headers{
  padding: 0 40px 0 16px;

}
@media (max-width: 850px) {
  .MuiAccordionSummary-content{
    flex-direction: column;
    width: 100% !important;
    /* margin-bottom: 0 !important; */
                    
  } 
  .MuiAccordionSummary-root{
    padding: 0px !important;
    flex-direction: column !important;

  }  
  .MuiTableCell-head{
    padding: 5px 10px !important;
  }
  .headers{
    padding: 0 5px 0 10px;

  }
}

.hidden{
  display: none !important;
}

.headers *{
  font-weight: bold !important
}

.listRight{
  display: flex;
  align-items: center;
}